import $ from 'jquery'

export default function initVideoPopup() {
    $('.js-launch-video').each(function () {
        let url = new URL($(this).attr('href'));
        if (url.hostname == 'play.vidyard.com') {
            let videoID = $(this).attr('href').split('/').pop().split('.')[0];
            let videoModal = '<div style="display: none;"><img class="vidyard-player-embed" data-uuid="' + videoID + '" data-v="4" data-type="lightbox" /></div>';
            $(videoModal).appendTo('body');
            $(this).on('click', function (e) {
                e.stopImmediatePropagation();
                e.preventDefault();
                let players = VidyardV4.api.getPlayersByUUID(videoID);
                let player = players[0];
                player.showLightbox();
                return false;
            });
        }
    });
}
import $ from 'jquery'
import 'slick-carousel'
const $doc = $(document)

function forumFaqs(e){
    e.preventDefault();
    if ($(this).closest('.forum__faq__accordion-item').hasClass('active')) {
        $('.forum__faq__accordion-item').removeClass('active faq-prev faq-next').addClass('non-active');
    } else {
        $('.forum__faq__accordion-item').removeClass('active').addClass('non-active');
        $('.forum__faq__accordion-item').removeClass('faq-prev');
        $('.forum__faq__accordion-item').removeClass('faq-next');
        $(this).closest('.forum__faq__accordion-item').addClass('active').removeClass('non-active');
        var activeIndex = $(".forum__faq__accordion-item.active").index();
        var prevIndex = activeIndex - 1;
        var nextIndex = activeIndex + 1;
        $('.forum__faq__accordion-item').eq(prevIndex).addClass('faq-prev');
        $('.forum__faq__accordion-item').eq(nextIndex).addClass('faq-next');
    }
    var $content = $(this).next();
    $content.slideToggle(500);
    $('.forum__faq__accordion-item .content').not($content).slideUp(500);
}

function forumSlider(){
    $('.js-forum-activities').slick({
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        speed: 900,
        cssEase: 'ease-out',
        arrows: true,
        variableWidth: true,
        dots: false,
        prevArrow: $('.js-forum-prev'),
        nextArrow: $('.js-forum-next'),
        responsive: [
            {
                breakpoint: 1180,
                settings: {
                    centerMode: true,
                    infinite: true,
                    centerPadding: '0',
                    arrows: false,
                    speed: 500,
                    initialSlide: 0,
                    dots: true
                }
            }
        ]
    });
}


function openForumMobileMenu(){
    $(".forum__mobile-menu__links").show();
}

function closeMobileMenu(){
    $(".forum__mobile-menu__links").hide();
}

function forumAgenda(){
    var regularColorText = $(".forum-agenda__days").attr('regularcolor');
    var activeColorText = $(".forum-agenda__days").attr('activecolor');
    var activeBorder = $(".forum-agenda__days").attr('activeborder');
    var activeWeight = $(".forum-agenda__days").attr('activeweight');

    var activeLabelBg = $(".forum-agenda__days").attr('activelabelbg');
    var activeLabelText = $(".forum-agenda__days").attr('activelabeltext');

    var regularLabelBorder = $(".forum-agenda__days").attr('regularlabelborder');
    var regularLabelText = $(".forum-agenda__days").attr('regularlabeltext');

    $(".forum-agenda__event").show();
    $(".forum-agenda__details").show().removeClass('hidden-agenda');

    var getVal = '#' + $(this).attr('dayVal');
    $(".forum-agenda__day").removeClass('active');
    $(".forum-agenda__day p").removeClass("forum-weight-" + activeWeight);
    $(".forum-agenda__day p").css('color', regularColorText);
    $(".forum-agenda__day div").css('color', regularLabelText);
    $(".forum-agenda__day div").css('background-color', '#fff');
    $(".forum-agenda__day div").css('border-color', regularLabelBorder);

    $(".forum-agenda__day-agenda").hide().removeClass('active');
    $(this).addClass('active');
    $(this).find('p').css('color', activeColorText);
    $(this).css('border-color', activeBorder);
    $(this).find('p').addClass("forum-weight-" + activeWeight);
    $(this).find('div').css('color', activeLabelText);
    $(this).find('div').css('border-color', activeLabelBg);
    $(this).find('div').css('background-color', activeLabelBg);
    $(getVal).addClass('active').show();
}

function mobileForumAgenda(){
    $(".forum-agenda__event").show();
    $(".forum-agenda__details").show().removeClass('hidden-agenda');
    $(".forum-agenda__mobile__list").hide();
    $(".forum-agenda__toggle-menu").removeClass('active');
    var dayLinkContent = $(this).clone();
    $(".forum-agenda__mobile-active-day").empty().append($(dayLinkContent).html());
    $(".forum-agenda__mobile__list a").removeClass('active-day');
    $(this).addClass('active-day');
    var mobileDay = $(this).attr('mobileDay');
    $('.forum-agenda__day-agenda--mobile').hide();
    $('.forum-agenda__day-agenda--mobile[mobileDay=' + mobileDay + ']').show();
}

function forumMobileDropdown(){
    jQuery(this).toggleClass('active');
    jQuery('.forum-agenda__mobile__list').toggle();
}

export default function initForumFns() {
    var activeColorText = $(".forum-agenda__days").attr('activecolor');
    var activeBorder = $(".forum-agenda__days").attr('activeborder');
    var activeWeight = $(".forum-agenda__days").attr('activeweight');

    var activeLabelBg = $(".forum-agenda__days").attr('activelabelbg');
    var activeLabelText = $(".forum-agenda__days").attr('activelabeltext');

    $('.forum-agenda__day.active').css('border-color', activeBorder);
    $('.forum-agenda__day.active').find('p').css('color', activeColorText);
    $('.forum-agenda__day.active').find('p').addClass("forum-weight-" + activeWeight);
    $('.forum-agenda__day.active').find('div').css('color', activeLabelText);
    $('.forum-agenda__day.active').find('div').css('border-color', activeLabelBg);
    $('.forum-agenda__day.active').find('div').css('background-color', activeLabelBg);

    var formBgColor = $(".forum-form").attr('formctabg');
    var formTextColor = $(".forum-form").attr('formctatext');

    if($(".marketo-form").length){
        var waitCta = setInterval(function(){
            if ($(".marketo-form .mktoForm button.mktoButton").length) {
                $(".marketo-form .mktoForm button.mktoButton").css('background-color', formBgColor);
                $(".marketo-form .mktoForm button.mktoButton").css('color', formTextColor);
            } 
            clearInterval(waitCta);
        }, 500)
    }

    $doc.on('click', '.js-forum-faq-link', forumFaqs)
    $doc.on('click', '.js-forum-menu', openForumMobileMenu)
    $doc.on('click', '.js-close-forum-menu', closeMobileMenu)
    $doc.on('click', '.forum-agenda__mobile__list a', mobileForumAgenda)
    $doc.on('click', '.forum-agenda__toggle-menu', forumMobileDropdown)
    $doc.on('click', '.forum-agenda__day', forumAgenda)

    if (jQuery('.forum__faq__accordion-item').length) {
        var firstElement = jQuery('.forum__faq__accordion-item').first();
        firstElement.addClass('active').removeClass('non-active');
        firstElement.find('.content').show();
    };

    if (jQuery('.js-forum-activities').length) {
        forumSlider()
    };
}
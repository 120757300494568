import $ from 'jquery'
const $doc = $(document)

function load_press_posts(page, press_type) {
    $('#press-posts-list').addClass('loading');
    $('#press-pagination').hide();

    $.ajax({
        url: SITE.ajaxUrl,
        type: 'POST',
        data: {
            action: 'get_press_posts_ajax',
            page: page,
            press_type: press_type,
        },
        success: function (response) {
            $("#press-posts-list").html(response);
            $('#press-posts-list').removeClass('loading');
            $('#press-pagination').show();
        },
        error: function () {
            console.log('Error loading more press posts.');
        }
    });
}

function load_press_pagination(press_type) {
    $('#press-posts-list').addClass('loading');
    $('#press-pagination').hide();

    $.ajax({
        url: SITE.ajaxUrl,
        type: 'POST',
        data: {
            action: 'get_press_posts_pagination',
            press_type: press_type,
        },
        success: function (response) {
            $('#press-pagination').html(response);
            $('#press-posts-list').removeClass('loading');
            $('#press-pagination').show();
        },
        error: function () {
            console.log('Error loading more press posts.');
        }
    });
}

function loadPressPosts(){
    var current_page = 1;
    var article_type = $(this).attr('data-cat');
    $('.js-normalpress-btns button').removeClass('active');
    $(this).addClass('active');

    load_press_posts(current_page, article_type);
    load_press_pagination(article_type);
}

function dropdownLoadPressPosts(){
    var current_page = 1;
    var article_type = $(this).val();

    load_press_posts(current_page, article_type);
    load_press_pagination(article_type);
}

function pressPostsPagination(){
    if ($('.press-grid').length) {
        $('html, body').animate({
            scrollTop: $(".press-grid").offset().top
        }, 500);
    }

    var current_li = $('#press-pagination li.current');

    if ($(this).hasClass('prev') || $(this).hasClass('next')) {

        if ($(this).css('opacity') == 0.5) return false;

        if ($(this).hasClass('prev')) {
            current_li.removeClass('current');
            current_li.prev().addClass('current');
            current_li = $('#press-pagination li.current');

            $('#press-pagination li:not(.prev, .next)').hide();
            $('#press-pagination li.prev, #press-pagination li.next').show();

            if (current_li.text() == '1') $('.js-normalpress .prev').css('opacity', '0.5');
            else $('.js-normalpress .prev').css('opacity', '1');

            $(current_li).show();
            if (current_li.text() != '1') $(current_li.prev()).show();
            else $(current_li.next().next()).show();

            $(current_li.next()).show();

        } else if ($(this).hasClass('next')) {

            current_li.removeClass('current');
            current_li.next().addClass('current');
            current_li = $('#press-pagination li.current');
            $('#press-pagination li:not(.prev, .next)').hide();
            $('#press-pagination li.prev, #press-pagination li.next').show();

            if (current_li.text() == $('#press-pagination li:not(.prev, .next)').length) $('.js-normalpress .next').css('opacity', '0.5');
            else $('.js-normalpress .next').css('opacity', '1');

            $(current_li).show();
            $(current_li.prev()).show();
            if (current_li.text() != $('#press-pagination li:not(.prev, .next)').length)  $(current_li.next()).show();
            else $(current_li.prev().prev()).show();

            $(current_li.prev()).show();
        }

    }
    else {
        $('#press-pagination li').removeClass('current');
        $(this).addClass('current');
    }

    current_li = $('#press-pagination li.current');
    var current_page = $(current_li).text();
    if ($('.press-grid').length) {
        var article_type = $('.js-normalpress-btns button.active').attr('data-cat');
    } else {
        var article_type = $('#article-type-select').val();
    }

    if (current_page == '1') $('.js-normalpress .prev').css('opacity', '0.5');
    else $('.js-normalpress .prev').css('opacity', '1');

    if (current_page == $('#press-pagination li:not(.prev, .next)').length) $('.js-normalpress .next').css('opacity', '0.5');
    else $('.js-normalpress .next').css('opacity', '1');

    load_press_posts(current_page, article_type);
}

export default function initNewsroomFn() {
    if ($('.press-grid').length) {
        load_press_posts(1);
        load_press_pagination();
        $doc.on('click', '.js-normalpress-btns button', loadPressPosts)
        $doc.on('click', '#press-pagination li', pressPostsPagination)
        $doc.on('change', '#article-type-select', dropdownLoadPressPosts)
    }
}
